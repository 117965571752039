import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Box, Typography, Button, TextField, Snackbar, Alert, IconButton, colors } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUtensils, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Add, BorderStyle, Remove } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import ConfirmationDialog from './screens/ConfirmationDialog';
import CheckoutConfirmationDialog from './screens/CheckoutConfirmationDialog';
import ImageSlider from './screens/ImageSlider';
import MenuModal from './screens/MenuModal';
import ItemModal from './screens/ItemModal';
import CustomizeVariableProductCart from './screens/CustomizeVariableProductCart';
import CartScreen from './screens/CartScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import CircularProgress from '@mui/material/CircularProgress';
import errorImage from './assets/emptycart.png';
import successImage from './assets/success.png';
import 'react-loading-skeleton/dist/skeleton.css';
import './Style.css';
import PromocodeScreen from './screens/PormocodeScreen';
import getCurrencySymbol from './screens/getCurrencySymbol';

const getCurrentUrl = () => {
  return window.location.href;
};

const extractIdsFromUrl = (url) => {
  const parsedUrl = new URL(url);
  const queryString = parsedUrl.search.substring(1);
  const parts = queryString.split('&');
  const id1 = parts[0];
  const id2 = parts[1];

  return { id1, id2 };
};

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}

const fetchWithAuth = async (url, options = {}) => {
  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;
  const headers = new Headers(options.headers || {});
  headers.append('Authorization', 'Basic ' + btoa(`${username}:${password}`));

  return fetch(url, { ...options, headers });
};

function getContrastingTextColor(backgroundColor) {
  const hex = backgroundColor?.replace('#', '');
  const r = parseInt(hex?.substring(0, 2), 16);
  const g = parseInt(hex?.substring(2, 4), 16);
  const b = parseInt(hex?.substring(4, 6), 16);
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 186 ? '#000000' : '#ffffff';
}


const App = () => {
  const currentUrl = getCurrentUrl();
  const { id1, id2 } = extractIdsFromUrl(currentUrl);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCustomizeVariableProductCartModalOpen, setIsCustomizeVariableProductCartModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [settings, setSettings] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [items, setItems] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingItems, setLoadingItems] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [holddialogOpen, setHoldDialogOpen] = useState(false);
  const [checkoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderOne, setLoaderOne] = useState(true);
  const [selectedCartItems, setSelectedCartItems] = useState(null);
  const [promocodeDatas, setPromocodeData] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const categoryRefs = useRef({});
  const [isError, setIsError] = useState(false)
  const [tableData, setTableData] = useState(null);
  const [qrsettigns, setQrsettigns] = useState(null);
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState('');
  const [checkout, setCheckout] = useState(false);
  const [images, setImages] = useState(['https://via.placeholder.com/800x400?text=Slide+1', 'https://via.placeholder.com/800x400?text=Slide+2', 'https://via.placeholder.com/800x400?text=Slide+3']);
  const [theme, setTheme] = useState(
    createTheme({
      palette: {
        primary: { main: '#c31f43' },
        secondary: { main: '#000000' }
      },
    })
  );
  useEffect(() => {
    setLoaderOne(true);
    const fetchProducts = async () => {
      try {
        const response = await fetchWithAuth(`https://smartaspos.com/api/appapi/restaurant_detais/${id1}/${id2}`);
        const data = await response.json();
        console.log(data);
        if (data.tabledata) {
          setQrsettigns(data.qrsettings);
          if (data.qrsettings) {
            if (data.qrsettings['slider_images'] && JSON.parse(data.qrsettings['slider_images'])) {
              const parsedImages = JSON.parse(data.qrsettings['slider_images']).map(imageUrl => `https://smartaspos.com/assets/sliderimages/${imageUrl}`);
              setImages(parsedImages);
            }
            setTheme(createTheme({
              palette: {
                primary: { main: data.qrsettings.primary_color },
                secondary: { main: data.qrsettings.secondary_color }
              }
            }));
          }
          setTableData(data.tabledata);
          setSettings((data.settings) ? data.settings : []);
          setCategories((data.categories) ? data.categories : []);
          setItems((data.products) ? data.products : []);
          setCartData((data.cartdata) ? data.cartdata : []);
          setPromocodeData((data.promocodes) ? data.promocodes : []);
          setPaymentModes((data.payment_modes) ? data.payment_modes : []);
          if (data.tbl_qr_checkout) {
            setCheckout(true);
          }
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.log(error);
        setError('Error fetching products.');
        setIsError(true);
      } finally {
        setLoaderOne(false);
        setLoadingCategories(false);
        setLoadingItems(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openItemModal = (item) => {
    setSelectedItem(item);
    if (item.product_varients !== '' && JSON.parse(item.product_varients).filter(Boolean).length !== 0) {
      if (JSON.parse(item.product_varients).filter(Boolean)[0].name !== '') {
        if (!cartData.some(cart => cart.product_id === item.product_id && cart.hold_status === '2')) {
          setIsItemModalOpen(true);
        }
        return;
      }
    }
    if (!cartData.some(cart => cart.product_id === item.product_id && cart.hold_status === '2')) {
      addToCart(tableData.restaurant_id, item.product_id, tableData.table_id, 'tableorder', [], '', 1);
    }
  };

  const addToCart = async (restaurantId, prodId, tableId, ordertype, addons, cart_item_note, quantity) => {
    setLoader(true);
    const url = 'https://smartaspos.com/api/appapi/add_to_cart';
    const formData = new FormData();
    formData.append('restaurant_id', restaurantId);
    formData.append('prod_id', prodId);
    formData.append('table_id', tableId);
    formData.append('ordertype', ordertype);
    formData.append('addon_options', JSON.stringify(addons));
    formData.append('cart_item_note', cart_item_note);
    formData.append('quantity', quantity);
    try {
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong! Please try again.');
      }

      const data = await response.json();
      console.log('Success:', data);
      setCartData((prevCartData) => [...prevCartData, data.cart]);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const handlePromoSelect = (promocode) => {
    setSelectedPromoCode(promocode);
    setSuccess('Promocode applied.');
    setIsPromoModalOpen(false);
  };

  const removePromoCode = () => {
    setSelectedPromoCode(null);
  };

  const handlePaymentModeChange = (id) => {
    setSelectedPaymentId(id);
  }

  const handleCheckout = async (paymentModeId) => {
    if (paymentModeId === '') {
      setError('Please select mode of payment');
    } else {
      setLoader(true);
      const url = 'https://smartaspos.com/api/appapi/qr_checkout';
      const formData = new FormData();
      formData.append('payment_mode_id', paymentModeId);
      formData.append('restaurant_id', tableData.restaurant_id);
      formData.append('promocode_data', JSON.stringify(selectedPromoCode));
      formData.append('table_id', tableData.table_id);
      try {
        const response = await fetchWithAuth(url, {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Something went wrong! Please try again.');
        }
        const data = await response.json();
        console.log('Success:', data);
        setCheckout(true);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoader(false);
      }
    }
  };


  const modifyCart = async (restaurantId, cart_id, action) => {
    setLoader(true);
    const url = 'https://smartaspos.com/api/appapi/cart_item_modify';
    const formData = new FormData();
    formData.append('restaurant_id', restaurantId);
    formData.append('cart_id', cart_id);
    formData.append('action', action);
    try {
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong! Please try again.');
      }

      const data = await response.json();
      console.log('Success:', data);
      const index = cartData.findIndex((cartitem) => cartitem.cart_id === cart_id);
      if (index !== -1) {
        const updatedCartData = [...cartData];
        if (action === 'add') {
          updatedCartData[index] = {
            ...updatedCartData[index],
            quantity: parseInt(updatedCartData[index].quantity) + 1,
            totle_price: ((parseInt(updatedCartData[index].quantity) + 1) * (parseFloat(updatedCartData[index].price))) + parseFloat(updatedCartData[index].addon_price),
          };
        } else if (action === 'remove') {
          if (parseInt(updatedCartData[index].quantity) === 1) {
            updatedCartData.splice(index, 1);
          } else {
            updatedCartData[index] = {
              ...updatedCartData[index],
              quantity: parseInt(updatedCartData[index].quantity) - 1,
              totle_price: ((parseInt(updatedCartData[index].quantity) - 1) * (parseFloat(updatedCartData[index].price))) + parseFloat(updatedCartData[index].addon_price),
            };
          }
        }
        setCartData(updatedCartData);
      }

      const selectedIndex = selectedCartItems.findIndex((cartitem) => cartitem.cart_id === cart_id);
      if (selectedIndex !== -1) {
        const updatedSelectedCartItems = [...selectedCartItems];
        if (action === 'add') {
          updatedSelectedCartItems[selectedIndex] = {
            ...updatedSelectedCartItems[selectedIndex],
            quantity: parseInt(updatedSelectedCartItems[selectedIndex].quantity) + 1,
            totle_price: ((parseInt(updatedSelectedCartItems[selectedIndex].quantity) + 1) * (parseFloat(updatedSelectedCartItems[selectedIndex].price))) + parseFloat(updatedSelectedCartItems[selectedIndex].addon_price),
          };
        } else if (action === 'remove') {
          if (parseInt(updatedSelectedCartItems[selectedIndex].quantity) === 1) {
            updatedSelectedCartItems.splice(selectedIndex, 1);
          } else {
            updatedSelectedCartItems[selectedIndex] = {
              ...updatedSelectedCartItems[selectedIndex],
              quantity: parseInt(updatedSelectedCartItems[selectedIndex].quantity) - 1,
              totle_price: ((parseInt(updatedSelectedCartItems[selectedIndex].quantity) - 1) * (parseFloat(updatedSelectedCartItems[selectedIndex].price))) + parseFloat(updatedSelectedCartItems[selectedIndex].addon_price),
            };
          }
        }
        setSelectedCartItems(updatedSelectedCartItems);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const closeItemModal = () => {
    setIsItemModalOpen(false);
  };

  const closeCustomizeVariableProductCartModal = () => {
    setIsCustomizeVariableProductCartModalOpen(false);
  };

  const handleCartModalClose = () => {
    setIsCartModalOpen(false);
  };

  const handleCheckoutModalClose = () => {
    setIsCheckoutModalOpen(false);
  };

  const handlePromoModalClose = () => {
    setIsPromoModalOpen(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveCategory(entry.target.getAttribute('data-category-id'));
          }
        });
      },
      { threshold: 0.1 }
    );

    Object.values(categoryRefs.current).forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, [categories]);

  useEffect(() => {
    const filtered = items.filter(item =>
      item.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [items, searchTerm]);

  const getItemCountPerCategory = (categoryId) => {
    return filteredItems.filter(item => item.product_main_category_id === categoryId).length;
  };

  useEffect(() => {
    if (categories && filteredItems) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const categoryId = entry.target.getAttribute('data-category-id');
              setActiveCategory(categoryId);
            }
          });
        },
        { threshold: 0.1 }
      );
      const updateObserver = () => {
        Object.values(categoryRefs.current).forEach((categoryRef) => {
          if (categoryRef && categoryRef instanceof Element) {
            observer.observe(categoryRef);
          }
        });
      };
      updateObserver();
      return () => {
        observer.disconnect();
      };
    }
  }, [categories, filteredItems, categoryRefs]);

  const handleSingleQuantityChange = (cartitem, action) => {
    modifyCart(tableData.restaurant_id, cartitem.cart_id, action);
  };

  const handleNewCustomization = () => {
    setIsCustomizeVariableProductCartModalOpen(false);
    setIsItemModalOpen(true);
  };

  const handleVariationQuantityChange = (cartitem) => {
    console.log(cartitem);
    setSelectedCartItems(cartitem);
    setIsCustomizeVariableProductCartModalOpen(true);
  }

  const handleClearCart = () => {
    setDialogOpen(true);
  };

  const handleHoldOrder = () => {
    setHoldDialogOpen(true);
  };

  const handlePlaceOrder = () => {
    if (cartData.filter(cart => cart.hold_status === '2').length !== 0) {
      setCheckoutDialogOpen(true);
      return;
    }
    setIsCheckoutModalOpen(true);
  };

  const handlePromocodeModal = () => {
    setIsPromoModalOpen(true);
  };

  const handleApplyPromocodeModal = (promocodeData) => {
    console.log(promocodeData);
  };

  const clearCart = async (restaurantId, table_id) => {
    setLoader(true);
    const url = 'https://smartaspos.com/api/appapi/clear_cart';
    const formData = new FormData();
    formData.append('restaurant_id', restaurantId);
    formData.append('table_id', table_id);
    try {
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong! Please try again.');
      }
      const data = await response.json();
      console.log('Success:', data);
      const updatedCartData = cartData.filter(item => item.hold_status !== '2');
      setCartData(updatedCartData);
      const updatedSelectedCartData = selectedCartItems.filter(item => item.hold_status !== '2');
      setSelectedCartItems(updatedSelectedCartData);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const holdOrder = async (restaurantId, table_id) => {
    setLoader(true);
    const url = 'https://smartaspos.com/api/appapi/hold_order';
    const formData = new FormData();
    formData.append('restaurant_id', restaurantId);
    formData.append('table_id', table_id);
    try {
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong! Please try again.');
      }
      const data = await response.json();
      console.log('Success:', data);
      const updatedCartData = cartData.map((entry) => {
        if (entry.table_id === table_id) {
          return { ...entry, hold_status: '1' };
        }
        return entry;
      });
      setCartData(updatedCartData);
      const updatedSelectedCartData = cartData.map((entry) => {
        if (entry.table_id === table_id) {
          return { ...entry, hold_status: '1' };
        }
        return entry;
      });
      setSelectedCartItems(updatedSelectedCartData);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const handleConfirmClearCart = () => {
    clearCart(tableData.restaurant_id, tableData.table_id);
    setDialogOpen(false);
  };

  const handleConfirmClearCartCheckout = async () => {
    await clearCart(tableData.restaurant_id, tableData.table_id);
    setCheckoutDialogOpen(false);
    setIsCheckoutModalOpen(true);
  };



  const handleHoldOrderPlace = () => {
    holdOrder(tableData.restaurant_id, tableData.table_id);
    setHoldDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseCheckoutDialog = () => {
    setCheckoutDialogOpen(false);
  };

  const handleHoldCloseDialog = () => {
    setHoldDialogOpen(false);
  };

  const handleAddtoCart = async (item, quantity, addons) => {
    const finalAddons = Object.values(addons);
    await addToCart(tableData.restaurant_id, item.product_id, tableData.table_id, 'tableorder', finalAddons, '', quantity);
    setIsItemModalOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loader &&
        <Box className='loader'>
          <CircularProgress />
        </Box>
      }
      {loaderOne ? (
        <Box className='loaderOne'>
          <CircularProgress />
        </Box>
      ) : (
        isError ? (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box component="section" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box className='mainBox' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box style={{ textAlign: 'center' }}>
                    <img src={errorImage} alt='error' style={{ width: '100%' }} />
                    <Typography variant="h5" gutterBottom>Your session has been expired as you have checked out. Kindly contact restaurant crew!</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          checkout ? (
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box component="section" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box className='mainBox' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box style={{ textAlign: 'center' }}>
                      <img src={successImage} alt='success' className="successImage" style={{ width: '100%' }} />
                      <Typography variant="h5" gutterBottom>Thank you for your order</Typography>
                      <Typography variant="h6" gutterBottom>Please pay at counter</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            < Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box component="section" style={{ position: 'relative', backgroundColor: (qrsettigns?.background_color !== '') ? qrsettigns?.background_color : '#ffffff', color: getContrastingTextColor((qrsettigns?.background_color !== '') ? qrsettigns?.background_color : '#ffffff') }}>
                  <Box className='mainBox'>
                    <ImageSlider images={images} />
                    <Box className='searchBox' style={{ backgroundColor: (qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }}>
                    <Box p={2}>
                      <TextField
                        fullWidth
                        label="Search Items"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ borderRadius: 20, outlineColor: '#ffffff' }}
                        InputLabelProps={{ style: { color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.7)` } }}
                        InputProps={{
                          endAdornment: (
                            <FontAwesomeIcon className='searchIcon' icon={faMagnifyingGlass} color={`rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.7)`} />
                          ),
                          style: { borderRadius: 50, color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'), outlineColor: '#ffffff' }
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'),
                            },
                            '&:hover fieldset': {
                              borderColor: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'),
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'),
                            },
                          },
                          borderRadius: 20,
                        }}
                      />
                      </Box>
                      <Box className='bottomMenuBox' style={{marginTop:10}}>
                      <Box className='menuHorizontal'>
                        {loadingCategories ? (
                          <Skeleton width={80} height={30} count={5} style={{ margin: '0 10px' }} />
                        ) : (
                          categories.map((category) => (
                            <Button
                              key={category.category_id}
                              variant='text'
                              style={{ backgroundColor: activeCategory === category.category_id ? qrsettigns['primary_color'] : 'initial', color: activeCategory === category.category_id ? getContrastingTextColor((qrsettigns['primary_color'] !== '') ? qrsettigns['primary_color'] : '#ffffff') : getContrastingTextColor((qrsettigns?.background_color !== '') ? qrsettigns?.background_color : '#ffffff') }}
                              className={`menuButton ${activeCategory === category.category_id ? 'active' : ''}`}
                              onClick={() => {
                                setActiveCategory(category.category_id);
                                const categoryElement = document.getElementById(`category-${category.category_id}`);
                                if (categoryElement) {
                                  categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                }
                              }}
                            >
                              {category.category_name}
                            </Button>
                          ))
                        )}
                      </Box>
                      <Button variant='contained' color='secondary' className='menuButton' onClick={openModal}>
                        <FontAwesomeIcon icon={faUtensils} style={{ paddingRight: 10 }} /> Menu
                      </Button>
                    </Box>
                    </Box>
                    <Box className="itemBox">
                      {loadingItems ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <Box style={{ display: 'flex', alignItems: 'center', padding: 15 }} key={index}>
                            <Skeleton width={80} height={80} />
                            <Box style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                              <Skeleton width="60%" />
                              <Skeleton width="40%" />
                            </Box>
                            <Skeleton width={50} height={30} />
                          </Box>
                        ))
                      ) : (
                        categories.map((category) => (
                          getItemCountPerCategory(category.category_id) !== 0 && (
                            <Box style={{ margin: 15, backgroundColor: qrsettigns.card_color, borderRadius: 20, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }}>
                              {qrsettigns.item_view_setting === '2' ? (
                                <Box style={{ padding: 15, scrollMargin: 88 }} key={category.category_id} id={`category-${category.category_id}`} data-category-id={category.category_id} ref={(el) => (categoryRefs.current[category.category_id] = el)}>
                                  <Typography variant="h6" gutterBottom className='ItemTitle'>{category.category_name}</Typography>
                                  <Grid container spacing={2}>
                                    {
                                      filteredItems.filter(item => item.product_main_category_id === category.category_id).map(item => (
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                          <Box
                                            style={{ borderColor: '#ddd', borderWidth: 1, borderStyle: 'solid', borderRadius: 10, paddingBottom: 10 }}
                                            key={item.product_id}
                                            id={`item-${item.product_id}`}
                                            onClick={() => openItemModal(item)}
                                          >
                                            <div className='griditemListImageDiv'>
                                              {item.product_image ? (
                                                <img src={`https://smartaspos.com/assets/product_image/${JSON.parse(item.product_image).file_name}`} alt={item.product_name} className='itemListImage' />
                                              ) : (
                                                <img src={`https://via.placeholder.com/800x400?text=${item?.product_name?.replace(" ", "%20")}`} alt={item.product_name} className='itemListImage' />
                                              )}
                                            </div>
                                            <div style={{ flex: 1, minHeight: '110px', paddingRight: 10, paddingLeft: 10 }}>
                                              <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{item.product_name}</Typography>
                                              <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>{getCurrencySymbol(settings.currency)} {parseFloat(item.product_price).toFixed(2)}</Typography>
                                            </div>
                                            <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                                              {item.product_varients !== '' && JSON.parse(item.product_varients).filter(Boolean).length !== 0 ? (
                                                JSON.parse(item.product_varients).filter(Boolean)[0].name !== '' ? (
                                                  cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2') ? (
                                                    <Box>
                                                      <Box className='directAddRemoveItem' style={{ backgroundColor: qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color), width: '100%' }}>
                                                        <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'remove')}><Remove /></IconButton>
                                                        <Typography className='addRemoveQty' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} variant="body1">{cartData.filter(cart => cart.product_id === item.product_id && cart.hold_status === '2').reduce((total, cdta) => total + parseInt(cdta.quantity), 0)}</Typography>
                                                        <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleVariationQuantityChange(cartData.filter(cart => cart.product_id === item.product_id && cart.hold_status === '2'))}><Add /></IconButton>
                                                      </Box>
                                                      <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                                    </Box>
                                                  ) : (
                                                    <Box>
                                                      <Button variant="outlined" color="primary" className='ItemAddButton'>Add <Add className='listAddIcon' /></Button>
                                                      <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                                    </Box>
                                                  )
                                                ) : (
                                                  cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2') ? (
                                                    <Box className='directAddRemoveItem' style={{ backgroundColor: qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color), width: '100%' }}>
                                                      <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'remove')}><Remove /></IconButton>
                                                      <Typography className='addRemoveQty' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} variant="body1">{cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2').quantity}</Typography>
                                                      <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'add')}><Add /></IconButton>
                                                    </Box>
                                                  ) : (
                                                    <Box>
                                                      <Button variant="outlined" color="primary" className='ItemAddButton'>Add <Add className='listAddIcon' /></Button>
                                                      <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                                    </Box>
                                                  )
                                                )
                                              ) : (
                                                cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2') ? (
                                                  <Box className='directAddRemoveItem' style={{ backgroundColor: qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color), width: '100%' }}>
                                                    <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'remove')}><Remove /></IconButton>
                                                    <Typography className='addRemoveQty' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} variant="body1">{cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2').quantity}</Typography>
                                                    <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'add')}><Add /></IconButton>
                                                  </Box>
                                                ) : (
                                                  <Box>
                                                    <Button variant="outlined" color="primary" className='ItemAddButton'>Add <Add className='listAddIcon' /></Button>
                                                    <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                                  </Box>
                                                )
                                              )}
                                            </div>
                                          </Box>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Box>
                              ) : (
                                <Box style={{ padding: 15, scrollMargin: 88 }} key={category.category_id} id={`category-${category.category_id}`} data-category-id={category.category_id} ref={(el) => (categoryRefs.current[category.category_id] = el)}>
                                  <Typography variant="h6" gutterBottom className='ItemTitle'>{category.category_name}</Typography>
                                  {
                                    filteredItems.filter(item => item.product_main_category_id === category.category_id).map(item => (
                                      <Box
                                        style={{ display: 'flex', alignItems: 'center', paddingTop: 15, paddingBottom: 15 }}
                                        key={item.product_id}
                                        id={`item-${item.product_id}`}
                                        onClick={() => openItemModal(item)}
                                      >
                                        <div className='itemListImageDiv'>
                                          {item.product_image ? (
                                            <img src={`https://smartaspos.com/assets/product_image/${JSON.parse(item.product_image).file_name}`} alt={item.product_name} className='itemListImage' />
                                          ) : (
                                            <img src={`https://via.placeholder.com/800x400?text=${item?.product_name?.replace(" ", "%20")}`} alt={item.product_name} className='itemListImage' />
                                          )}
                                        </div>
                                        <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                          <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{item.product_name}</Typography>
                                          <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>{getCurrencySymbol(settings.currency)} {parseFloat(item.product_price).toFixed(2)}</Typography>
                                        </div>
                                        {item.product_varients !== '' && JSON.parse(item.product_varients).filter(Boolean).length !== 0 ? (
                                          JSON.parse(item.product_varients).filter(Boolean)[0].name !== '' ? (
                                            cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2') ? (
                                              <Box>
                                                <Box className='directAddRemoveItem' style={{ backgroundColor: qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color) }}>
                                                  <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'remove')}><Remove /></IconButton>
                                                  <Typography className='addRemoveQty' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} variant="body1">{cartData.filter(cart => cart.product_id === item.product_id && cart.hold_status === '2').reduce((total, cdta) => total + parseInt(cdta.quantity), 0)}</Typography>
                                                  <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleVariationQuantityChange(cartData.filter(cart => cart.product_id === item.product_id && cart.hold_status === '2'))}><Add /></IconButton>
                                                </Box>
                                                <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                              </Box>
                                            ) : (
                                              <Box>
                                                <Button variant="outlined" color="primary" className='ItemAddButton'>Add <Add className='listAddIcon' /></Button>
                                                <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                              </Box>
                                            )
                                          ) : (
                                            cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2') ? (
                                              <Box className='directAddRemoveItem' style={{ backgroundColor: qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color) }}>
                                                <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'remove')}><Remove /></IconButton>
                                                <Typography className='addRemoveQty' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} variant="body1">{cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2').quantity}</Typography>
                                                <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'add')}><Add /></IconButton>
                                              </Box>
                                            ) : (
                                              <Box>
                                                <Button variant="outlined" color="primary" className='ItemAddButton'>Add <Add className='listAddIcon' /></Button>
                                              </Box>
                                            )
                                          )
                                        ) : (
                                          cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2') ? (
                                            <Box className='directAddRemoveItem' style={{ backgroundColor: qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color) }}>
                                              <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'remove')}><Remove /></IconButton>
                                              <Typography className='addRemoveQty' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} variant="body1">{cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2').quantity}</Typography>
                                              <IconButton className='addRemoveBtn' style={{ color: getContrastingTextColor(qrsettigns.primary_color) }} onClick={() => handleSingleQuantityChange(cartData.find(cart => cart.product_id === item.product_id && cart.hold_status === '2'), 'add')}><Add /></IconButton>
                                            </Box>
                                          ) : (
                                            <Box>
                                              <Button variant="outlined" color="primary" className='ItemAddButton'>Add <Add className='listAddIcon' /></Button>
                                            </Box>
                                          )
                                        )}
                                      </Box>
                                    ))}
                                </Box>
                              )}
                            </Box>
                          ))))}
                      <Box style={{ display: 'flex', alignItems: 'center', padding: 15, height: 120 }} />
                    </Box>
                  </Box>
                  {
                    isModalOpen ? (
                      <Box className='menuBoxOne' >
                        <Box className='menuHorizontal' />
                        <Button variant='contained' color='secondary' className='menuButton' onClick={closeModal}>
                          <FontAwesomeIcon icon={faXmark} style={{ paddingRight: 10 }} /> Close
                        </Button>
                      </Box>
                    ) : (
                      <Box className='menuBox' style={{ backgroundColor: qrsettigns?.background_color }}>
                        {cartData.length !== 0 && (
                          <Box className='cartOptions'>
                            <Box className='cartItemCount'>
                              {cartData.filter(cart => cart.hold_status === '2').length === 1 ? (
                                <Typography>{cartData.filter(cart => cart.hold_status === '2').length} item added</Typography>
                              ) : (
                                <Typography>{cartData.filter(cart => cart.hold_status === '2').length} items added</Typography>
                              )}
                              {cartData.filter(cart => cart.hold_status === '1').length === 1 ? (
                                <Typography>{cartData.filter(cart => cart.hold_status === '1').length} item ordered</Typography>
                              ) : (
                                <Typography>{cartData.filter(cart => cart.hold_status === '1').length} items ordered</Typography>
                              )}
                            </Box>
                            <Box className='viewCartBtnDiv'>
                              <Button className='viewCartBtn' variant='contained' color='primary' style={{ marginLeft: 'auto' }} onClick={() => setIsCartModalOpen(true)}>View Order</Button>
                            </Box>
                            <Box className='removeCartBtnDiv'>
                              <Button className='removeCartBtn' variant='contained' color='secondary' onClick={handleClearCart}><FontAwesomeIcon icon={faXmark} /></Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  <MenuModal open={isModalOpen} onClose={closeModal} categories={categories} activeCategory={activeCategory} setActiveCategory={setActiveCategory} itemCounts={categories.map(cat => getItemCountPerCategory(cat.category_id))} qrsettigns={qrsettigns} getContrastingTextColor={getContrastingTextColor} settings={settings} />
                  <ItemModal open={isItemModalOpen} onClose={closeItemModal} item={selectedItem} handleAddtoCart={handleAddtoCart} qrsettigns={qrsettigns} getContrastingTextColor={getContrastingTextColor} settings={settings} />
                  <CustomizeVariableProductCart open={isCustomizeVariableProductCartModalOpen} item={selectedItem} onClose={closeCustomizeVariableProductCartModal} cartItems={selectedCartItems} handleAddtoCart={handleSingleQuantityChange} handleNewCustomization={handleNewCustomization} qrsettigns={qrsettigns} getContrastingTextColor={getContrastingTextColor} settings={settings} />
                  <CartScreen open={isCartModalOpen} onClose={handleCartModalClose} items={items} cartData={cartData} handleAddtoCart={handleSingleQuantityChange} handleVariationQuantityChange={handleVariationQuantityChange} handleNewCustomization={handleNewCustomization} handleHoldOrder={handleHoldOrder} handlePlaceOrder={handlePlaceOrder} qrsettigns={qrsettigns} getContrastingTextColor={getContrastingTextColor} hexToRgb={hexToRgb} settings={settings} />
                  <CheckoutScreen open={isCheckoutModalOpen} onClose={handleCheckoutModalClose} items={items} cartData={cartData} handlePromocodeModal={handlePromocodeModal} paymentModes={paymentModes} qrsettigns={qrsettigns} getContrastingTextColor={getContrastingTextColor} hexToRgb={hexToRgb} settings={settings} handleCheckout={handleCheckout} selectedPromoCode={selectedPromoCode} removePromoCode={removePromoCode} handlePaymentModeChange={handlePaymentModeChange} selectedPaymentId={selectedPaymentId} />
                  <PromocodeScreen open={isPromoModalOpen} onClose={handlePromoModalClose} promocodeDatas={promocodeDatas} cartData={cartData} handleApplyPromocodeModal={handleApplyPromocodeModal} qrsettigns={qrsettigns} getContrastingTextColor={getContrastingTextColor} hexToRgb={hexToRgb} settings={settings} handlePromoSelect={handlePromoSelect} />
                  <ConfirmationDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onConfirm={handleConfirmClearCart}
                    message="Are you sure you want to clear the cart?"
                    qrsettigns={qrsettigns}
                    getContrastingTextColor={getContrastingTextColor}
                  />
                  <CheckoutConfirmationDialog
                    open={checkoutDialogOpen}
                    onClose={handleCloseCheckoutDialog}
                    onConfirm={handleConfirmClearCartCheckout}
                    message="Few unordered items need to clear before checkout"
                    qrsettigns={qrsettigns}
                    getContrastingTextColor={getContrastingTextColor}
                  />
                  <ConfirmationDialog
                    open={holddialogOpen}
                    onClose={handleHoldCloseDialog}
                    onConfirm={handleHoldOrderPlace}
                    message="Are you sure you want hold order?"
                    qrsettigns={qrsettigns}
                    getContrastingTextColor={getContrastingTextColor}
                  />
                </Box>
              </Grid>
            </Grid>
          )
        ))
      }
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </ThemeProvider >
  );
};

export default App;
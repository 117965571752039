import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, IconButton, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Add, Remove, ArrowBack, ArrowForwardIos } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import discountIcon from '../assets/discount.png';
import getCurrencySymbol from './getCurrencySymbol';
const CheckoutScreen = ({ open, onClose, items, cartData, handlePromocodeModal, paymentModes, qrsettigns, getContrastingTextColor, hexToRgb, settings, handleCheckout, selectedPromoCode, removePromoCode, handlePaymentModeChange, selectedPaymentId }) => {
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const calculateDiscount = (promoCode, orderAmount) => {
        const promoMinAmount = parseFloat(promoCode.promo_min_amount);
        const promoDiscount = parseFloat(promoCode.promo_discount);
        const maxDiscount = parseFloat(promoCode.max_discount);
    
        if (orderAmount >= promoMinAmount) {
            let discount = (promoDiscount / 100) * orderAmount;
            if (promoCode.promo_discount_type === 'per') {
                discount = Math.min(discount, maxDiscount);
            }
            return discount;
        }
        return 0;
    };

    const modalClassName = open ? 'cartModalContainer open' : 'cartModalContainer';
    const modalContentClassName = open ? 'cartModalContent open' : 'cartModalContent';
    return (
        <Box className={modalClassName}>
            {cartData && (
                <Box className={modalContentClassName} style={{ backgroundColor: qrsettigns?.background_color }}>
                    {open && (
                        <>
                            <Box className='cartModal'>
                                <Box className='cartModalHeader' onClick={onClose}>
                                    <Button variant='text' style={{ color: getContrastingTextColor(qrsettigns?.background_color), minWidth: 'unset' }}><ArrowBack /></Button>
                                    <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>Checkout</Typography>
                                </Box>
                                <Box className='cartModalBody'>
                                    <Box style={{ margin: 15, backgroundColor: qrsettigns.card_color, borderRadius: 20, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }}>
                                        {cartData.map((cartItem, index, array) => (
                                            <Box style={{ display: 'flex', alignItems: 'center', padding: 15, borderBottom: index === array.length - 1 ? 'none' : '1px dashed #ddd' }}>
                                                <div className='itemListImageDiv'>
                                                    {items.find((item) => item.product_id === cartItem.product_id).product_image ? (
                                                        <img src={`https://smartaspos.com/assets/product_image/${JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_image).file_name}`} alt={items.find((item) => item.product_id === cartItem.product_id).product_name} className='itemListImage' />
                                                    ) : (
                                                        <img src={`https://via.placeholder.com/800x400?text=${items.find((item) => item.product_id === cartItem.product_id).product_name.replace(" ", "%20")}`} alt={items.find((item) => item.product_id === cartItem.product_id).product_name} className='itemListImage' />
                                                    )}
                                                </div>
                                                <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                                    <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{items.find((item) => item.product_id === cartItem.product_id).product_name}</Typography>
                                                    {JSON.parse(cartItem.product_addons).map(addons => (
                                                        <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>1 x {addons.type}</Typography>
                                                    ))}
                                                    <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>{getCurrencySymbol(settings.currency)} {parseFloat(cartItem.totle_price).toFixed(2)}</Typography>
                                                </div>
                                                {items.find((item) => item.product_id === cartItem.product_id).product_varients !== '' && JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_varients).filter(Boolean).length !== 0 ? (
                                                    JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_varients).filter(Boolean)[0].name !== '' ? (
                                                        <Box>
                                                            <Box className='holdDirectAddRemoveItem'>
                                                                <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                                <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                                <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                            </Box>
                                                            <Typography className='customisableText' style={{ color: `rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff'))}, 0.5)` }}>customisable</Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box className='holdDirectAddRemoveItem'>
                                                            <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                            <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                            <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                        </Box>
                                                    )) : (
                                                    <Box className='holdDirectAddRemoveItem'>
                                                        <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                        <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                        <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box style={{ margin: 15, backgroundColor: qrsettigns.card_color, borderRadius: 20, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }}>
                                        <Box>
                                            <Box className='orderData'>
                                                <Typography>SubTotal</Typography>
                                                <Typography>{getCurrencySymbol(settings.currency)} {cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0).toFixed(2)} </Typography>
                                            </Box>
                                            <Box className='orderData'>
                                                <Typography>Service Charge</Typography>
                                                <Typography>{getCurrencySymbol(settings.currency)} 0.00</Typography>
                                            </Box>
                                            {selectedPromoCode && (
                                                <Box className='orderData'>
                                                <Typography>Promo Discount {selectedPromoCode.promo_discount_type === 'per' && `${selectedPromoCode.promo_discount} %`}</Typography>
                                                <Typography>{getCurrencySymbol(settings.currency)} {calculateDiscount(selectedPromoCode, cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0)).toFixed(2)}</Typography>
                                                </Box>
                                            )}
                                            <Box className='orderDataTotal'>
                                                <Typography style={{ fontWeight: 'bold' }}>Order Total</Typography>
                                                {selectedPromoCode ? (
                                                    <Typography style={{ fontWeight: 'bold' }}>{getCurrencySymbol(settings.currency)} {(cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0) - calculateDiscount(selectedPromoCode, cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0))).toFixed(2)}</Typography>
                                                ):(
                                                    <Typography style={{ fontWeight: 'bold' }}>{getCurrencySymbol(settings.currency)} {cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0).toFixed(2)}</Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {selectedPromoCode ? (
                                    <Box className='promoCodeDiv' style={{ margin: 15, backgroundColor: qrsettigns.card_color, borderRadius: 20, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }} display="flex" alignItems="center" justifyContent='space-between' onClick={removePromoCode}>
                                        <Typography display="flex" alignItems="center"><img src={discountIcon} alt="Discount" style={{ width: 24, height: 24, marginRight: 8 }} /> {selectedPromoCode.promocode}</Typography>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </Box>
                                    ):(
                                        <Box className='promoCodeDiv' style={{ margin: 15, backgroundColor: qrsettigns.card_color, borderRadius: 20, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }} display="flex" alignItems="center" justifyContent='space-between' onClick={handlePromocodeModal}>
                                            <Typography display="flex" alignItems="center"><img src={discountIcon} alt="Discount" style={{ width: 24, height: 24, marginRight: 8 }} /> View All Restaurant Coupons</Typography>
                                            <ArrowForwardIos />
                                        </Box>
                                    )}
                                    <Box className='paymentModesDiv' style={{ margin: 15, backgroundColor: qrsettigns.card_color, borderRadius: 20, boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', color: getContrastingTextColor((qrsettigns?.card_color !== '') ? qrsettigns?.card_color : '#ffffff') }} display="flex" alignItems="center" justifyContent='space-between'>
                                        <div style={{ flex: 1 }}>
                                            <RadioGroup value={selectedPaymentId} onChange={(event) => handlePaymentModeChange(event.target.value)} style={{ width: '100%' }}>
                                                <FormControlLabel
                                                    value={'cash'}
                                                    control={<Radio />}
                                                    label={<div className={`labelContainer ${selectedPaymentId === 'cash' ? 'boldLabel' : ''}`}><span>Cash</span></div>}
                                                    className="radioLabel"
                                                    style={{ padding: 10 }}
                                                />
                                                <FormControlLabel
                                                    value={'card'}
                                                    control={<Radio />}
                                                    label={<div className={`labelContainer ${selectedPaymentId === 'card' ? 'boldLabel' : ''}`}><span>Card</span></div>}
                                                    className="radioLabel"
                                                    style={{ borderTop: '1px solid #ddd', padding: 10 }}
                                                />
                                                <FormControlLabel
                                                    value={'upi'}
                                                    control={<Radio />}
                                                    label={<div className={`labelContainer ${selectedPaymentId === 'upi' ? 'boldLabel' : ''}`}><span>Online (QR Pay)</span></div>}
                                                    className="radioLabel"
                                                    style={{ borderTop: '1px solid #ddd', padding: 10 }}
                                                />
                                                {paymentModes.map((paymentMode, index) => (
                                                    <FormControlLabel
                                                        value={paymentMode.payment_mode_id}
                                                        control={<Radio />}
                                                        label={<div className={`labelContainer ${selectedPaymentId === paymentMode.payment_mode_id ? 'boldLabel' : ''}`}><span>{paymentMode.payment_mode_name}</span></div>}
                                                        className="radioLabel"
                                                        style={{ borderTop: '1px solid #ddd', padding: 10 }}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </div>
                                    </Box>

                                    <Box style={{ display: 'flex', alignItems: 'center', padding: 15, height: 120 }} />
                                </Box>
                            </Box>
                            <Box className='cartModalFooter'>
                                <div className='checkoutBtnDiv'>
                                    <Button className='checkoutBtn' variant='contained' color='primary' style={{ marginLeft: 'auto' }} onClick={() => handleCheckout(selectedPaymentId)}>Pay</Button>
                                </div>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default CheckoutScreen;